/**
 * Groups Gear Menu API
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1134723192/Groups+Gear+Menu+API
 */
import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

const METHOD = 'GET';

const getUrl = (teamId: number) => `/api/web/v1/ui/teams/${teamId}/groups/gear_menu`;

const defaultPermissionSchema = z
  .object({
    disabled: z.boolean(),
  })
  .optional();

const responseSchema = z.object({
  access_permission: defaultPermissionSchema,
  // TODO: Wait API to be ready
  // "associate_groups":defaultPermissionSchema,
  granular_control: z
    .object({
      disabled: z.boolean(),
      grant_granular_control_configurable: z.boolean(),
    })
    .optional(),
});

const execute = (teamId: number) => checkResponse(axios({ method: METHOD, url: getUrl(teamId) }), responseSchema);

export const getGroupGearMenuPermissionsService = { getUrl, execute, method: METHOD, responseSchema };
