import axios from 'axios';
import { z } from 'zod';

import { featureControl } from '@/feature/toggle';
import { checkResponse } from '@/services/utils';

export const fileTransferSchema = z.object({
  default: z.literal('3'),
  options: z.array(z.union([z.literal('0'), z.literal('3')])), // 0: Off, 3: upload & download
});

export const hideTrayIconSchema = z.object({
  default: z.literal('0'),
  options: z.array(z.union([z.literal('0'), z.literal('1')])), // 0: No, 1: Yes
});

export const audioSchema = z.object({
  default: z.literal('1'),
  options: z.array(z.union([z.literal('0'), z.literal('1'), z.literal('2')])), // "0": Both(N/A), "1": Remote, "2": Local
});

export const blankScreenSchema = z.object({
  default: z.literal('0'),
  options: z.array(z.union([z.literal('0'), z.literal('1')])), // 0: No, 1: Yes
});

export const lockScreenSchema = z.object({
  default: z.literal('1'),
  options: z.array(z.union([z.literal('0'), z.literal('1')])), // 0: No, 1: Yes
});

export const lockKmSchema = z.object({
  default: z.literal('0'),
  options: z.array(z.union([z.literal('0'), z.literal('1')])), // 0: No, 1: Yes
});

export const maxFpsSchema = z.object({
  default: z.literal('2'),
  options: z.array(z.union([z.literal('0'), z.literal('1'), z.literal('2'), z.literal('3'), z.literal('10')])), // "0": Low, "1": Medium, "2": High, "3": Ultra High, "10": Turbo
});

export const maxAudioSchema = z.object({
  default: z.literal('1'),
  options: z.array(z.union([z.literal('0'), z.literal('1'), z.literal('2'), z.literal('3'), z.literal('4')])), // "0": Low (reserved), "1": Medium, "2": High, "3": Ultra High - 256K, "4": Ultra High - 384K
});

export const oscAuthSchema = z.object({
  default: z.literal('1'),
  options: z.array(z.union([z.literal('0'), z.literal('1')])), // 0: No, 1: Yes
});

// setting="0": No
// setting="1" & option="1": Reject connection after request expires (At login screen, reject automatically)
// setting="1" & option="2": Reject connection after request expires (At login screen, allow automatically)
// setting="2": Allow connection after request expires
const connOptionsSchema = z.object({
  setting: z.enum(['0', '1', '2']),
  option: z.enum(['1', '2']).optional(),
});

export const connPermSchema = z.object({
  default: connOptionsSchema,
  options: z.array(connOptionsSchema),
});

/**
 * New Streamer Preference Policy API
 * Return a list of available streamer preference policy items
 * @param teamId
 * @param emmPolicyId
 * @doc https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/streamer_preferences.md
 */

const getStreamerPreferencePoliciesNewUrl = (teamId: number, emmPolicyId: number) =>
  `/api/web/v1/teams/${teamId}/emm_policies/${emmPolicyId}/streamer_policy/new`;

const streamerPreferencePoliciesNewSchema = z.object({
  '01000003': fileTransferSchema.nullable(), // file_transfer
  '01000005': featureControl.getToggle('PCP_2825__Policy_streamer_preference_display_android_support_items')
    ? hideTrayIconSchema.nullable()
    : z.undefined(), // hide_tray_icon,
  '01000007': featureControl.getToggle('PCP_2825__Policy_streamer_preference_display_android_support_items')
    ? audioSchema.nullable()
    : z.undefined(), // audio
  '01000008': featureControl.getToggle('PCP_2825__Policy_streamer_preference_display_android_support_items')
    ? blankScreenSchema.nullable()
    : z.undefined(), // blank_screen
  '01000009': featureControl.getToggle('PCP_2825__Policy_streamer_preference_display_android_support_items')
    ? lockScreenSchema.nullable()
    : z.undefined(), // lock_screen
  '01000010': featureControl.getToggle('PCP_2825__Policy_streamer_preference_display_android_support_items')
    ? lockKmSchema.nullable()
    : z.undefined(), // lock_km
  '01000012': maxFpsSchema.nullable(), // max_fps
  '01000013': maxAudioSchema.nullable(), // max_audio
  '01000014': oscAuthSchema.nullable(), // osc_auth
  '01000015': connPermSchema.nullable(), // conn_perm
});
export type StreamerPreferencePoliciesNew = z.infer<typeof streamerPreferencePoliciesNewSchema>;

const streamerPreferencePoliciesNewRecordSchema = z.record(streamerPreferencePoliciesNewSchema);
export type streamerPreferencePoliciesNewRecord = z.infer<typeof streamerPreferencePoliciesNewRecordSchema>;

export function getMockStreamerPolicies() {
  const mockData: StreamerPreferencePoliciesNew = {
    '01000003': {
      default: '3',
      options: ['0', '3'], //  "0": off, "3": upload & download on
    },
    '01000005': {
      default: '0',
      options: ['0', '1'], // "0": No, "1": Yes
    },
    '01000007': {
      default: '1',
      options: ['0', '1', '2'], // "0": Both(N/A), "1": Remote, "2": Local
    },
    '01000008': {
      default: '0',
      options: ['0', '1'], // "0": No, "1": Yes
    },
    '01000009': {
      default: '1',
      options: ['0', '1'], // "0": No, "1": Yes
    },
    '01000010': {
      default: '0',
      options: ['0', '1'], // "0": No, "1": Yes
    },
    '01000012': {
      default: '2',
      options: ['0', '1', '2', '3', '10'], // "0": Low, "1": Medium, "2": High, "3": Ultra High, "10": Turbo
    },
    '01000013': {
      default: '1',
      options: ['1', '2', '3', '4'], // "0": Low (reserved), "1": Medium, "2": High, "3": Ultra High - 256K, "4": Ultra High - 384K
    },
    '01000014': {
      default: '1',
      options: ['0', '1'], // "0": No, "1": Yes
    },
    '01000015': {
      default: { setting: '0' },
      options: [{ setting: '0' }, { setting: '1', option: '1' }, { setting: '1', option: '2' }, { setting: '2' }],
      // setting="0": No
      // setting="1" & option="1": Reject connection after request expires (At login screen, reject automatically)
      // setting="1" & option="2": Reject connection after request expires (At login screen, allow automatically)
      // setting="2": Allow connection after request expires
    },
  };

  return checkResponse(
    new Promise((resolve) =>
      resolve({
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
        data: {
          result: 20200,
          messages: [],
          data: mockData,
        },
      }),
    ),
    streamerPreferencePoliciesNewSchema,
  );
}

export function getStreamerPoliciesNew(teamId: number, emmPolicyId: number) {
  return checkResponse(axios.get(getStreamerPreferencePoliciesNewUrl(teamId, emmPolicyId)), streamerPreferencePoliciesNewSchema);
}

export const getStreamerPoliciesNewService = {
  getUrl: getStreamerPreferencePoliciesNewUrl,
  method: 'GET',
  execute: getStreamerPoliciesNew,
};
