import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { infoQueryService } from '@/services/users';

export const useInventoryAccess = () => {
  const infoQuery = useQuery({
    queryKey: ['inventory', 'info'],
    queryFn: () => infoQueryService.execute({ team_member_permissions: ['inventory'] }),
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
  });

  const isTeamAccessible = infoQuery.data
    ? Boolean(Object.values(infoQuery.data).some((item) => item.team_member_permissions.inventory))
    : false;

  return {
    isFetched: infoQuery.isFetched,
    canAccess: isTeamAccessible,
  };
};
