import axios from 'axios';

import { featureControl } from '@/feature/toggle';
import type { GroupOptions } from '@/modules/Group/types';
import type { ResponseData } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

import type { ComputerGroupList, Group, GroupDetail, GroupListOptions } from './types';

export { Group };

const FT_MANAGE_ASSOCIATES = featureControl.getToggle('PCP_1336__Manage_Group_Associates');

/**
 * @deprecated Use `getGroupListService` from `@/services/teams/groups/getGroupListService.ts` instead.
 */
export function getGroupList(teamId: number, options: GroupListOptions) {
  const { members_count, computers_count, admins_count, associates_count, member_status, group_type } = options;

  const params = new URLSearchParams({
    members_count: members_count.toString(),
    computers_count: computers_count.toString(),
    admins_count: admins_count.toString(),
    // Should change to  value ? 'true' : 'false' at future refactoring
  });

  if (FT_MANAGE_ASSOCIATES && associates_count) {
    params.set('associates_count', associates_count ? 'true' : 'false');
  }

  if (member_status && member_status.length > 0) {
    params.set('members_status', member_status.join(','));
  }

  if (group_type) {
    params.set('group_type', group_type);
  }

  return new Promise<Array<Group>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<Group> }>(`/api/web/v1/teams/${teamId}/groups`, {
        params,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getComputerGroupList(teamId: number) {
  return new Promise<ComputerGroupList>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ComputerGroupList }>(`/api/web/v1/teams/${teamId}/groups?group_type=computer`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getGroupDetail(teamId: number, groupId: number) {
  return new Promise<GroupDetail>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GroupDetail }>(`/api/web/v1/teams/${teamId}/groups/${groupId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function createGroup(teamId: number, options: GroupOptions) {
  const { name, isComputerGroup, isMemberGroup, isConnectionPool } = options;
  return new Promise<{ result: number; status: string; data: { id: number } }>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { id: number } }>(`/api/web/v1/teams/${teamId}/groups`, {
        name: name,
        is_computer_group: isComputerGroup,
        is_member_group: isMemberGroup,
        connection_pool: isConnectionPool ?? false,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ result, status: 'success', data });
        } else {
          reject({ result, status: messages });
        }
      })
      .catch(() => {
        reject({ result: 400, status: 'network error' });
      });
  });
}

export function deleteGroup(teamId: number, groupId: number) {
  return new Promise<Record<string, unknown>>((resolve, reject) => {
    axios
      .delete<ResponseData & { data: Record<string, unknown> }>(`/api/web/v1/teams/${teamId}/groups/${groupId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateGroup(teamId: number, groupId: number, options: GroupOptions) {
  const { name, isComputerGroup, isMemberGroup, isConnectionPool } = options;
  return new Promise<{ id: number }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { id: number } }>(`/api/web/v1/teams/${teamId}/groups/${groupId}`, {
        name: name,
        is_computer_group: isComputerGroup,
        is_member_group: isMemberGroup,
        connection_pool: isConnectionPool ?? false,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject(messages.join(', '));
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function changeGroupAdmin(teamId: number, groupId: number, addAdmins: Array<number>, removeAdmins: Array<number>) {
  return new Promise<{
    success: Array<number>;
    failed: Array<number>;
  }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { success: Array<number>; failed: Array<number> } }>(
        `/api/web/v1/teams/${teamId}/groups/${groupId}/admins/change`,
        {
          add_admins: addAdmins,
          remove_admins: removeAdmins,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function changeGroupMembers(teamId: number, groupId: number, addMember: Array<number>, removeMember: Array<number>) {
  return new Promise<{
    success: Array<number>;
    failed: Array<number>;
  }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { success: Array<number>; failed: Array<number> } }>(
        `/api/web/v1/teams/${teamId}/groups/${groupId}/members/change`,
        {
          add_members: addMember,
          remove_members: removeMember,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function changeGroupComputers(teamId: number, groupId: number, addComputers: Array<number>, removeComputers: Array<number>) {
  return new Promise<{
    success: Array<number>;
    fail: Array<number>;
  }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { success: Array<number>; fail: Array<number> } }>(
        `/api/web/v1/teams/${teamId}/groups/${groupId}/computers/change`,
        {
          add_computers: addComputers,
          remove_computers: removeComputers,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getGroupMemberList(teamId: number, groupId: number) {
  return new Promise<Array<number>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<number> }>(`/api/web/v1/teams/${teamId}/groups/${groupId}/members`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getGroupComputerList(teamId: number, groupId: number) {
  return new Promise<Array<number>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<number> }>(`/api/web/v1/teams/${teamId}/groups/${groupId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getGroupAdminList(teamId: number) {
  return new Promise<{ member_id: Array<number> }>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { member_id: Array<number> } }>(`/api/web/v1/teams/${teamId}/groups/admins`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getGroupAdminForGroup(teamId: number, groupId: number) {
  return new Promise<{ member_id: Array<number> }>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { member_id: Array<number> } }>(`/api/web/v1/teams/${teamId}/groups/${groupId}/admins`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type ComputerIdList = Array<number>;
type AssignComputerToDefaultGroupResult = { success: ComputerIdList; fail: ComputerIdList };
export function assignComputerToDefaultGroup(teamId: number, computerIds: ComputerIdList) {
  return new Promise<AssignComputerToDefaultGroupResult>((resolve, reject) => {
    // TODO: Ask for rename `fail` to `failed`
    axios
      .delete<ResponseData & { data: AssignComputerToDefaultGroupResult }>(`/api/web/v1/teams/${teamId}/groups/computers/remove_groups`, {
        data: {
          ids: computerIds,
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type AssociatesPair = {
  team_member_id: number;
  tag_name: string;
};

export function getGroupAssociates(teamId: number, groupId: number) {
  return new Promise<Array<AssociatesPair>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<AssociatesPair> }>(`/api/web/v1/teams/${teamId}/groups/${groupId}/associates`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function changeGroupAssociates(teamId: number, groupId: number, addAssociates: Array<number>, removeAssociates: Array<number>) {
  return new Promise<{ success: Array<number>; failed: Array<number> }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { success: Array<number>; failed: Array<number> } }>(
        `/api/web/v1/teams/${teamId}/groups/${groupId}/associates/change`,
        {
          add_associates: addAssociates,
          remove_associates: removeAssociates,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject(messages.join(', '));
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
